import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import useAnimation from 'hooks/useAnimationNew';
import Box from 'Components/Box';
import colors from 'theme/colors';
import { getVwLg, getVwSm, mediaLgDown, mediaLgUp } from 'theme/helpers/css';

import introM from './json/intro-m.json';
import introPc from './json/intro-pc.json';
import skip from './json/skip.json';
import { IntroContext } from './IntroProvider';
import { config, useTransition } from '@react-spring/core';
import { animated } from '@react-spring/web';
import useIsMobile from 'hooks/useIsMobile';

const Container = styled(animated.div)`
  background-color: ${colors.deepBlue};
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 777;

  ${mediaLgDown} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const IntroContainer = styled(Box)`
  ${mediaLgUp} {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const SkipContainer = styled(Box)`
  cursor: pointer;
  z-index: 10;

  svg {
    width: ${getVwSm('160px')} !important;
    height: auto !important;
  }

  ${mediaLgUp} {
    width: max-content;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;

    svg {
      width: ${getVwLg('198px')} !important;
      height: auto !important;
    }
  }
`;

const Intro: React.FC = () => {
  const isMobile = useIsMobile();
  const { isIntroShown, hideIntro } = useContext(IntroContext);
  const transitions = useTransition(isIntroShown, {
    from: { transform: 'translateY(0%)' },
    leave: { transform: 'translateY(100%)' },
    config: config.slow,
  });

  const [introContainer] = useAnimation(
    {
      animationData: isMobile ? introM : introPc,
      autoplay: true,
      loop: false,
    },
    {
      complete: () => requestAnimationFrame(() => hideIntro()),
    }
  );

  const [skipContainer, skipInstance] = useAnimation({
    animationData: skip,
    autoplay: true,
    loop: false,
  });

  const handleSkip = () => {
    if (skipInstance.currentFrame < 200) {
      skipInstance.playSegments([170, 200], true);
      skipInstance.addEventListener('complete', () => requestAnimationFrame(() => hideIntro()));
    } else {
      requestAnimationFrame(() => hideIntro());
    }
  };

  return transitions(
    (styles, item) =>
      item && (
        <Container style={styles}>
          <IntroContainer ref={introContainer} />
          <SkipContainer ref={skipContainer} onClick={handleSkip} />
        </Container>
      )
  );
};

export default Intro;
