import React, { useRef } from 'react';
import styled from 'styled-components';
import Box from 'Components/Box';
import Flex from 'Components/Flex';
import MenuItem, { IMenuItem } from './MenuItem';
import { getVwLg, getVwSm, mediaLgUp, mediaLgDown } from 'theme/helpers/css';
import colors from 'theme/colors';
import { EnumPages, EnumServices, NBSP, URLs } from '../../constants';
import { EnumFontFamily, EnumTextType, TextStylesLg } from 'theme';
import { Title } from 'Components/Typography';
import Disclaimer, { EnumDisclaimers } from 'Components/Disclaimer';

const NavigationMenu = styled(Box).attrs({
  pb: { sm: 34, lg: 192 },
})`
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: ${getVwSm('12px')};
  grid-auto-rows: ${getVwSm('192px')};
  grid-template-columns: ${getVwSm('351px')};
  justify-content: center;

  ${mediaLgUp} {
    grid-gap: ${getVwLg('16px')} ${getVwLg('32px')};
    grid-template: repeat(2, ${getVwLg('320px')}) / repeat(2, ${getVwLg('775px')});
  }
`;

const ServicesMenu = styled(NavigationMenu).attrs({
  pb: 0,
  mb: { sm: 34, lg: 88 },
  id: '#services',
})`
  grid-template-rows: ${getVwSm('192px')} ${getVwSm('152px')} ${getVwSm('114px')};

  ${mediaLgDown} {
    & > * {
      align-items: flex-end;
      text-align: right;
    }
  }

  ${mediaLgUp} {
    grid-template: ${getVwLg('396px')} ${getVwLg('200px')} / ${getVwLg('1024px')} ${getVwLg('548px')};

    & > :first-child {
      grid-row: 1/3;

      ${Title} {
        ${TextStylesLg[EnumFontFamily.introscript + EnumTextType.h2]}
      }
    }

    & > *:not(:last-child) {
      justify-content: flex-start;
    }

    & > *:not(:last-child) {
      align-items: center;
      text-align: center;
    }
  }
`;

const leftAlignedIndent = {
  pl: { sm: 18, lg: 36 },
  pr: { sm: 120, lg: 280 },
};

const rightAlignedIndent = {
  pl: { ...leftAlignedIndent.pl, lg: 264 },
  pr: { ...leftAlignedIndent.pr, lg: 0 },
};

const pages: [IMenuItem, IMenuItem, IMenuItem, IMenuItem] = [
  {
    title: 'Проблематика',
    text: 'Почему у человека возникает зрительное напряжение',
    link: {
      to: URLs.problem,
    },
    color: colors.green,
    bgColor: colors.paleGreen,
    indent: leftAlignedIndent,
    illustrationStyles: EnumPages.PROBLEM,
  },
  {
    title: 'Симптоматика',
    text: 'Компьютерный Зрительный Синдром: его симптомы и последствия',
    link: {
      to: URLs.symptoms,
    },
    color: colors.deepRed,
    bgColor: colors.paleRed,
    indent: rightAlignedIndent,
    illustrationStyles: EnumPages.SYMPTOMS,
  },
  {
    title: 'Небольшой тест',
    text: `Поможет вам оценить нагрузку глаз в${NBSP}течение дня`,
    link: {
      to: URLs.test,
    },
    color: colors.purple,
    bgColor: colors.palePurple,
    indent: leftAlignedIndent,
    illustrationStyles: EnumPages.TEST,
  },
  {
    title: 'Полезные советы',
    text: 'Помогут вам сохранить ваше зрение',
    color: colors.mint,
    bgColor: colors.paleMint,
    indent: { ...rightAlignedIndent, pr: { ...leftAlignedIndent.pr, lg: 36 } },
    illustrationStyles: EnumPages.ADVICES,
    link: {
      to: URLs.advices,
    },
  },
];

const services: [IMenuItem, IMenuItem, IMenuItem] = [
  {
    title: 'Запишитесь',
    text: (
      <>
        На приём к офтальмологу
        <br />
        на сайте Госуслуг
      </>
    ),
    link: {
      text: 'Перейти',
      href: URLs.makeAppointment,
    },
    color: colors.greenPea,
    bgColor: colors.paleGreenPea,
    indent: { pt: { lg: 245 }, pl: { sm: 180, lg: 0 }, pr: { ...leftAlignedIndent.pl, lg: 0 } },
    illustrationStyles: EnumServices.APPOINTMENT,
  },
  {
    title: 'Подпишитесь',
    text: `На полезную рассылку с${NBSP}советами и без спама`,
    link: {
      text: 'Оставить Email',
    },
    color: colors.scampi,
    bgColor: colors.paleScampi,
    indent: { pt: { sm: 0, lg: 148 }, pl: { sm: 136, lg: 96 }, pr: { ...leftAlignedIndent.pl, lg: 96 } },
    illustrationStyles: EnumServices.SUBSCRIBE,
  },
  {
    title: 'Выберите на карте',
    text: (
      <>
        Удобную вам клинику <br />и запишитесь на приём! Скоро!
      </>
    ),
    color: colors.tulipTree,
    bgColor: colors.paleTulipTree,
    indent: { pl: { ...leftAlignedIndent.pl, sm: 78 }, pr: { ...leftAlignedIndent.pl, lg: 0 } },
    illustrationStyles: EnumServices.MAP,
  },
];

export const Menu: React.FC = () => {
  const navMenu = useRef();

  return (
    <Flex pt={{ sm: 34, lg: 88 }} column end>
      <NavigationMenu ref={navMenu}>
        {pages.map(item => (
          <MenuItem key={item.title} {...item} />
        ))}
      </NavigationMenu>

      <ServicesMenu>
        {services.map(item => (
          <MenuItem key={item.title} {...item} />
        ))}
      </ServicesMenu>

      <Disclaimer type={EnumDisclaimers.NOT_DOCTOR} />
    </Flex>
  );
};

export default Menu;
