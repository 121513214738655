import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Box from 'Components/Box';
import Flex from 'Components/Flex';
import useIsMobile from 'hooks/useIsMobile';
import { getVwLg, getVwSm, mediaLgUp } from 'theme/helpers/css';
import { URLs } from '../../constants';
import { sentissUrl } from 'Components/Footer/constants';
import useAnimation from 'hooks/useAnimationNew';
import bannerM from './json/banner-m.json';
import bannerPc from './json/banner-pc.json';
import { Link } from 'gatsby';
import Disclaimer, { EnumDisclaimers, DisclaimerWrapper } from 'Components/Disclaimer';
import { IntroContext } from 'Containers/Intro/IntroProvider';
import { useSpring } from '@react-spring/core';
import { animated } from '@react-spring/web';
import useScrollListener from 'hooks/useScrollListener';
import _ from 'lodash';
import { TransitionLink } from 'Containers/PageTransition';

const Container = styled(Box).attrs({
  pb: 80,
})`
  position: relative;
`;

const AnimationContainer = styled(Flex).attrs({ middle: true })`
  min-height: ${getVwSm('560px')};

  ${mediaLgUp} {
    min-height: ${getVwLg('920px')};
  }

  svg {
    width: 100%;
    heitgh: auto;
  }
`;

const InvisibleLink = styled.a`
  position: absolute;
  width: ${getVwSm('240px')};
  height: ${getVwSm('50px')};
  cursor: pointer;

  bottom: ${getVwSm('140px')};
  transform: rotate(3deg);
  z-index: 10;

  ${mediaLgUp} {
    width: ${getVwLg('305px')};
    height: ${getVwLg('48px')};
  }
`;

const NigthDropsLink = styled(InvisibleLink).attrs({
  as: TransitionLink,
  to: URLs.nightdrops,
  target: '_blank',
})`
  left: ${getVwSm('64px')};

  ${mediaLgUp} {
    left: ${getVwLg('248px')};
    bottom: ${getVwLg('298px')};
    transform: rotate(-3.5deg);
  }
`;

const SentissLink = styled(InvisibleLink).attrs({
  href: sentissUrl,
})`
  left: ${getVwSm('62px')};

  ${mediaLgUp} {
    left: unset;
    right: ${getVwLg('270px')};
    bottom: ${getVwLg('258px')};
    transform: rotate(1.5deg);
  }
`;

enum EnumBannerStates {
  DAY,
  NIGHT,
}

const disclaimerSpringConf = {
  from: {
    opacity: 1,
  },
  to: {
    opacity: 0,
  },
};

const Banner: React.FC = () => {
  const isMobile = useIsMobile();
  const getAnimData = () => (isMobile ? bannerM : bannerPc);
  const [state, setState] = useState(EnumBannerStates.DAY);
  const { isIntroShown } = useContext(IntroContext);

  const handleEnterFrame = (e: any) => e.currentTime >= 120 && setState(EnumBannerStates.NIGHT);
  const handleComplete = () => setState(EnumBannerStates.DAY);

  const [container, instance] = useAnimation(
    {
      animationData: getAnimData(),
      autoplay: false,
      loop: true,
    },
    {
      enterFrame: handleEnterFrame,
      loopComplete: handleComplete,
    }
  );

  const [isDisclaimerHidden, setIsDisclaimerHidden] = useState(false);
  const [disclaimerStyles] = useSpring({ ...disclaimerSpringConf, reverse: !isDisclaimerHidden }, [isDisclaimerHidden]);

  useEffect(() => {
    !isIntroShown && instance.isLoaded && instance.play();
  }, [isIntroShown, instance.isLoaded]);

  const animate = (e: any) => {
    if (e.target.documentElement.scrollTop > 100) setIsDisclaimerHidden(true);
    else setIsDisclaimerHidden(false);
  };

  useScrollListener(_.throttle(animate, 100));

  return (
    <Container>
      <AnimationContainer ref={container} />
      {isMobile ? (
        state === EnumBannerStates.DAY ? (
          <SentissLink />
        ) : (
          <NigthDropsLink />
        )
      ) : (
        <>
          <NigthDropsLink />
          <SentissLink />
        </>
      )}
      <DisclaimerWrapper as={animated.div} style={disclaimerStyles}>
        <Disclaimer type={EnumDisclaimers.BEFORE_USING} />
      </DisclaimerWrapper>
    </Container>
  );
};

export default Banner;
