import Box from 'Components/Box';
import useIsMobile from 'hooks/useIsMobile';
import React from 'react';
import colors from 'theme/colors';
import Menu from './Menu';
import Banner from './TitleBlock';

const Main: React.FC = () => {
  const isMobile = useIsMobile();

  if (isMobile === undefined) return null;

  return (
    <Box bgColor={colors.paleBlue}>
      <Banner />
      <Menu />
    </Box>
  );
};

export default Main;
