import React, { ReactNode } from 'react';
import Flex, { IFlex } from 'Components/Flex';
import Button from 'Components/Button';
import styled, { ThemeProvider, css } from 'styled-components';
import { Title, HandText } from 'Components/Typography';
import { EnumTextType } from 'theme';
import { getVwLg, getVwSm, mediaLgUp } from 'theme/helpers/css';
import { EnumPages, EnumServices } from '../../constants';
import problemBgM from './img/menu_problem-m.svg';
import problemBgPc from './img/menu_problem-pc.svg';
import testBgM from './img/menu_test-m.svg';
import testBgPc from './img/menu_test-pc.svg';
import symptomsBgM from './img/menu_symptoms-m.svg';
import symptomsBgPc from './img/menu_symptoms-pc.svg';
import advicesBgM from './img/menu_advices-m.svg';
import advicesBgPc from './img/menu_advices-pc.svg';

import appointmentBgM from './img/menu_appointment-m.svg';
import subscribeBgM from './img/menu_subscribe-m.svg';
import mapBgM from './img/menu_map-m.svg';
import appointmentBgPC from './img/menu_appointment-pc.svg';
import subscribeBgPc from './img/menu_subscribe-pc.svg';
import mapBgPc from './img/menu_map-pc.svg';

export interface IMenuItem {
  title: string;
  text: string | ReactNode;
  link?: any;
  indent: any;
  color: string;
  bgColor: string;
  illustrationStyles?: EnumPages | EnumServices;
  unavailable?: boolean;
  className?: string;
}

const MenuItemPseudoStyles: { [k: string]: any } = {
  [EnumPages.PROBLEM]: css`
    width: ${getVwSm('356px')};
    height: ${getVwSm('192px')};
    left: 0;
    bottom: 0;
    background-image: url('${problemBgM}');

    ${mediaLgUp} {
      width: ${getVwLg('776px')};
      height: ${getVwLg('392px')};
      background-image: url('${problemBgPc}');
    }
  `,
  [EnumPages.TEST]: css`
    width: ${getVwSm('356px')};
    height: ${getVwSm('192px')};
    left: 0;
    bottom: 0;
    background-image: url('${testBgM}');

    ${mediaLgUp} {
      width: ${getVwLg('784px')};
      height: ${getVwLg('378px')};
      background-image: url('${testBgPc}');
    }
  `,
  [EnumPages.SYMPTOMS]: css`
    width: ${getVwSm('351px')};
    height: ${getVwSm('192px')};
    left: 0;
    bottom: 0;
    background-image: url('${symptomsBgM}');

    ${mediaLgUp} {
      width: ${getVwLg('784px')};
      height: ${getVwLg('396px')};
      left: unset;
      right: 0;
      background-image: url('${symptomsBgPc}');
    }
  `,
  [EnumPages.ADVICES]: css`
    width: ${getVwSm('359px')};
    height: ${getVwSm('205px')};
    left: 0;
    bottom: ${getVwSm('-12px')};
    background-image: url('${advicesBgM}');

    ${mediaLgUp} {
      width: ${getVwLg('795px')};
      height: ${getVwLg('388px')};
      left: unset;
      right: 0;
      bottom: ${getVwLg('-27px')};
      background-image: url('${advicesBgPc}');
    }
  `,
  [EnumServices.APPOINTMENT]: css`
    width: ${getVwSm('403px')};
    height: ${getVwSm('248px')};
    right: 0;
    bottom: 0;
    background-image: url('${appointmentBgM}');

    ${mediaLgUp} {
      width: ${getVwLg('1024px')};
      height: ${getVwLg('792px')};
      background-image: url('${appointmentBgPC}');
    }
  `,
  [EnumServices.SUBSCRIBE]: css`
    width: ${getVwSm('388px')};
    height: ${getVwSm('195px')};
    right: 0;
    bottom: 0;
    background-image: url('${subscribeBgM}');

    ${mediaLgUp} {
      width: ${getVwLg('548px')};
      height: ${getVwLg('527px')};
      background-image: url('${subscribeBgPc}');
    }
  `,
  [EnumServices.MAP]: css`
    width: ${getVwSm('365px')};
    height: ${getVwSm('148px')};
    right: 0;
    bottom: 0;
    background-image: url('${mapBgM}');

    ${mediaLgUp} {
      width: ${getVwLg('560px')};
      height: ${getVwLg('260px')};
      unset: 0;
      left: 0;
      background-image: url('${mapBgPc}');
    }
  `,
};

const addIllustrationStyles = ({ illustrationStyles, color, bgColor }: IMenuItem) => {
  if (typeof illustrationStyles !== 'undefined')
    return css`
      background-color: unset;

      &::after {
        position: absolute;
        background: center / 100% auto no-repeat;
        ${MenuItemPseudoStyles[illustrationStyles]}
        content: '';
      }
    `;

  return css`
    border-radius: ${getVwSm('20px')};
    border: ${getVwSm('2px')} solid ${color};
    background-color: ${bgColor};

    ${mediaLgUp} {
      border-radius: ${getVwLg('20px')};
      border-width: ${getVwLg('3px')};
    }
  `;
};

const Container = styled(Flex).attrs(props => ({
  column: true,
  center: true,
  ...props,
}))<IFlex & IMenuItem>`
  opacity: ${({ unavailable }) => unavailable && '0.5'};

  position: relative;
  width: 100%;
  height: 100%;

  & > * {
    z-index: 1;
  }

  ${addIllustrationStyles}

  ${mediaLgUp} {
    transition: transform 200ms;

    &:hover {
      transform: scale(1.05);
    }
  }
`;

const MenuItem: React.FC<IMenuItem> = ({ title, text, link, indent, ...props }) => {
  const { color, bgColor } = props;
  const theme = { body: { color }, button: { color: bgColor, bgColor: color } };

  return (
    <ThemeProvider theme={theme}>
      <Container {...{ ...props, ...indent }}>
        <Title textType={{ sm: EnumTextType.h5, lg: EnumTextType.h3 }}>{title}</Title>
        <HandText>{text}</HandText>
        {link && (
          <Button to={link.to} href={link.href} mt={{ sm: 16, lg: 20 }} mx={{ sm: 8, lg: 136 }}>
            {link.text || 'Читать'}
          </Button>
        )}
      </Container>
    </ThemeProvider>
  );
};

export default MenuItem;
