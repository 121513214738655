import React from 'react';
import Layout from 'Containers/Layout';
import Footer, { FooterThemes } from 'Components/Footer';
import { EnumPages } from '../constants';
import Main from 'Containers/Main';
import colors from 'theme/colors';
import Intro from 'Containers/Intro';
import Logo from 'Components/Logo';

const mainTheme = {
  bgColor: colors.paleBlue,
  body: {
    color: colors.moonYellow,
  },
  disclaimer: {
    bgColor: colors.blue90,
    color: colors.paleBlue90,
  },
  logo: {
    bgColor: colors.deepBlue,
    color: colors.moonYellow,
  },
};

const IndexPage: React.FC = () => {
  return (
    <Layout theme={mainTheme}>
      <Logo />
      <Intro />
      <Main />
      <Footer theme={FooterThemes[EnumPages.MAIN]} />
    </Layout>
  );
};

export default IndexPage;
